import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';
import {
  layoutHorizontalSpacing,
  layoutHorizontalTabletSpacing,
  menuVerticalSpacing,
  pageTopSpacing,
} from '@shared/constants/layout/paddings';

const pageHeaderHeight = 108;
const menuHeight = 68;

export const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflow: 'hidden',
    },
    content: {
      overflowY: 'auto',
      height: `calc(100vh - ${pageHeaderHeight + menuHeight}px)`,
      padding: spacing(pageTopSpacing, layoutHorizontalSpacing),
      gap: spacing(4),
      alignContent: 'flex-start',

      [breakpoints.down('xs')]: {
        padding: spacing(pageTopSpacing, layoutHorizontalTabletSpacing),
      },
    },
    filters: {
      backgroundColor: colors.white.lightest,
      boxShadow: '0 1.5px 2.5px 0 rgb(0 18 52 / 10%)',
      height: menuHeight,
      overflowX: 'auto',
      padding: spacing(menuVerticalSpacing, layoutHorizontalSpacing),
      position: 'relative',
      zIndex: 2,
    },
    pagination: {
      marginLeft: spacing(3),
    },

    filterBtn: {
      marginLeft: spacing(3),
    },
    viewBtn: {},
    search: {},
    searchFieldInput: {
      minWidth: 250,
      marginRight: spacing(3),
    },
    rootLoading: {
      minHeight: '100vh',
      position: 'relative',
      width: '100%',
    },
    divider: {
      width: 1,
      height: 36,
      backgroundColor: colors.grey.lightish,
      borderRadius: 4,
      margin: spacing(0, 2),
    },
    dropdownBtnIcon: {
      color: colors.grey.darker,
      fontSize: 18,
    },
    noDataView: {
      margin: '0 auto',
    },
  });
